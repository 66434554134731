import React, { FC } from 'react';
import { Container, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const ErrorCode = styled.h1`
  font-size: 7rem;
  color: #495057;
`;

const NotFoundPage: FC<PageProps> = ({ navigate }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO article title="Page not found" />
      <Container>
        <div className="container flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <div className="w-50 text-center">
            <FontAwesomeIcon icon={faFrown} size="8x" />
            <ErrorCode>404</ErrorCode>
            <h2 className="mt-4">{t('page.not_found.title')}</h2>
            <h6 className="mt-4">{t('page.not_found.desc')}</h6>
            <p className="mt-4">
              <Button onClick={() => navigate(-1)}>{t('page.not_found.back')}</Button>
            </p>
          </div>
        </div>
      </Container>
    </Layout>
  );
};
export default NotFoundPage;
